<template>
    <div class="ratings"
        data-toggle="tooltip"
        v-if="fullstars > 0"
        :title="getStarTitle">
        <i v-for="(n, index) in fullstars"
            class="fa fa-star"></i>
        <i v-if="halfstar"
            class="fas fa-star-half-alt"></i>
        <i v-for="(n, index) in emptystars"
            class="fa fa-star-o"></i>
    </div>
</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'star-display',
    	props: {
    		stars: Number
    	},
    	data: function() {
    		return {
    			totalStars: 5
    		};
    	},
    	updated: function() {
    		$('[data-toggle="tooltip"]').tooltip();
    		$('[data-toggle="popover"]').popover();
    	},
    	computed: {
    		getStarTitle: function() {
    			if (this.stars && parseInt(this.stars) > 0) {
    				return (parseFloat(this.stars).toLocaleString("de-DE", {
    					maximumFractionDigits: 2
    				}) + "/5 Bewertung");

    				return '';

    			} else {
    				return '';
    			}
    		},
    		fullstars: function() {
    			var fullStars = parseInt(this.stars) || 0;
    			var remaingStar = this.stars - parseInt(this.stars);
    			if (remaingStar >= 0.80) {
    				fullStars += 1;
    			}
    			return fullStars;
    		},
    		halfstar: function() {
    			/*0.21 to 0.79 half star*/
    			/*0.80 and above full star*/
    			var remaingStar = this.stars - parseInt(this.stars);
    			return ((remaingStar >= 0.21 && remaingStar <= 0.79) ? true : false);
    		},
    		emptystars: function() {
    			if (this.halfstar) {
    				return this.totalStars - (this.fullstars) - 1;
    			} else {
    				return this.totalStars - this.fullstars;
    			}
    		}
    	}
    };
</script>